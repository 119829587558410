import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header />

      <main className="flex flex-1 max-w-6xl mx-auto px-4 py-8 md:p-8 w-full">
        {children}
      </main>

      <footer className="bg-dark">
        <nav className="flex justify-center max-w-4xl mx-auto p-2 md:p-2 text-sm">
          <p className="text-white">
            © {(new Date).getFullYear()+` `}
            <a
              className="font-bold no-underline text-white"
              href="https://santigarcor.me"
            >
              Santiago Garcia
            </a>
          </p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
